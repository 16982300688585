import { FC } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { useIsMobile } from '@contexts/mobile-detector-provider';
import { UAH } from '@lib/constants/common-constants';
import { roundAndFormatNumber } from '@utils/formatting';
import { Icon } from '../icon/Icon';

import classes from './ProductPrice.module.scss';

interface IProps {
  className?: string;
  price: number;
  specialPrice?: number;
  largePrice?: boolean;
  specialPriceEndDate?: string;
  mediumPrice?: boolean;
  isFinalizeOrder?: boolean;
}
const ProductPrice: FC<IProps> = ({
  className,
  price,
  specialPrice,
  largePrice,
  specialPriceEndDate,
  mediumPrice,
  isFinalizeOrder
}) => {
  const isMobile = useIsMobile();

  return (
    <div className={clsx(classes.price, { [className as string]: className })}>
      {price !== specialPrice && specialPrice && specialPriceEndDate ? (
        <>
          <div className={classes.priceItem}>
            <span
              className={clsx(classes.priceValue, classes.discountValue, {
                [classes.largePrice]: largePrice,
                [classes.mediumPrice]: mediumPrice
              })}
            >
              {roundAndFormatNumber(specialPrice)}
              &nbsp;
              {UAH}
            </span>
            <span className={classes.deliveryDate}>
              <Icon type="truck" className={classes.icon} size={isMobile ? 14 : 16} />
              до {dayjs(specialPriceEndDate).format('DD.MM')}
            </span>
          </div>
          <div className={classes.priceItem}>
            <span
              className={clsx(classes.priceValue, {
                [classes.largePrice]: largePrice,
                [classes.mediumPrice]: mediumPrice
              })}
            >
              {price}
              &nbsp;
              {UAH}
            </span>
            <span className={classes.deliveryDate}>з {dayjs(specialPriceEndDate).add(1, 'day').format('DD.MM')}</span>
          </div>
        </>
      ) : isFinalizeOrder && price !== specialPrice ? (
        <>
          <span className={clsx(classes.priceWithoutDiscount, { [classes.largePrice]: largePrice })}>
            {roundAndFormatNumber(price)}
            &nbsp;
            {UAH}
          </span>
          <span className={clsx(classes.priceWithDiscount, { [classes.largePrice]: largePrice })}>
            {roundAndFormatNumber(specialPrice)}
            &nbsp;
            {UAH}
          </span>
        </>
      ) : (
        <span className={clsx(classes.regularPrice, { [classes.largePrice]: largePrice })}>
          {price || 0}
          &nbsp;
          {UAH}
        </span>
      )}
    </div>
  );
};

export { ProductPrice };
